<template>
  <div class="mini_game_bet">
    <div class="mini_game">
      <div class="m1 dmr jwdmr">
        <div class="mini_fram">
          <leisure-game-ifram-view>
            <iframe id="jw-gamefram" scrolling="no" frameborder="0"
                    src="https://jp-domi.lock-global.com/game/tajja/"></iframe>
          </leisure-game-ifram-view>
        </div>
      </div>
      <div class="m2">
        <div class="game_tab">
          <leisure-bet-masker :show-masker="showMasker"></leisure-bet-masker>
          <leisure-timer-comp
              ref="ltimer"
              :leisure-game-info="leisureGameInfo"
              @timerStop="timerStop" @refreshTimer="refreshTimer">
            <span slot="title" class="text-orange"><i class="fa fa-star"></i>로얄섯다</span>
          </leisure-timer-comp>

          <!--선택부분-->
          <div class="mini_bet_box">
            <div class="mg">
              <span class="t">홍이 / 청이 </span>

              <div class="mg_btn b2 bg-red"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_ROYAL_SUTDA_NORMAL && selectedInfo.selectedWay === 2}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_ROYAL_SUTDA_NORMAL,leisureGameInfo.leisureGame.id,2,  normalConfig.odds2, normalConfig, '홍이')">
                <div class="r rred">
                  <span class="n">홍이</span>
                  <span class="b">{{normalConfig.odds2}}</span>
                </div>
              </div>
              <div class="mg_btn b2 bg-blue"
                   :class="{'active_minigame':selectedInfo.selectedAttr === leisureConst.LEISURE_ATTRIBUTE_ROYAL_SUTDA_NORMAL && selectedInfo.selectedWay === 1}"
                   @click="selected(leisureConst.LEISURE_ATTRIBUTE_ROYAL_SUTDA_NORMAL,leisureGameInfo.leisureGame.id,1, normalConfig.odds1, normalConfig, '청이')">
                <div class="r rblue">
                  <span class="n">청이</span>
                  <span class="b">{{normalConfig.odds1}}</span>
                </div>
              </div>


            </div>
          </div>

          <!--파워볼 베팅카트-->
          <leisure-bet-cart-comp
              ref="betCart" :kind="kind"
              :selected-info="selectedInfo">
            로얄섯다
          </leisure-bet-cart-comp>


        </div>
      </div>
      <div class="m3">
        <!--최근 배팅내역-->
        <leisure-lately-bet-list-comp
            ref="latelyBetList"
            :kind="kind"></leisure-lately-bet-list-comp>

      </div>



    </div>
  </div>
</template>

<script>
import SubTitle from "../../../components/SubTitle";
import LeisureTimerComp from "../../../components/leisuregame/LeisureTimerComp";
import LeisureBetCartComp from "../../../components/leisuregame/LeisureBetCartComp";
import leisureConst from "../../../common/leisureConst";
import sportsConst from "../../../common/sportsConst";
import LeisureBetMasker from "../../../components/leisuregame/LeisureBetMasker";
import {leisureGameMixin} from "../../../common/mixin";
import {getLeisureGameAttributeConfig} from "../../../network/leisureRequest";
import LeisureLatelyBetListComp from "../../../components/leisuregame/LeisureLatelyBetListComp";
import LeisureGameView from "../../../components/leisuregame/LeisureGameView";
import LeisureGameLinks from "../LeisureGameLinks";
import LeisureGameIframView from "../LeisureGameIframView";
import LeisureCompGameLinks from "../LeisureCompDonghangGameLinks";
import {RECEIVE_CURRENTLEISUREGAMECOMP, RECEIVE_CURRENTLEISUREGAMEID} from "../../../store/mutation-types";

export default {

  name: "RoyalSutda",
  mixins:[leisureGameMixin],
  components: {
    LeisureCompGameLinks,
    LeisureGameIframView,
    LeisureGameLinks,
    LeisureGameView,
    LeisureLatelyBetListComp, LeisureBetMasker, LeisureBetCartComp, LeisureTimerComp, SubTitle},
  data() {
    return {
      kind: leisureConst.LEISURE_KIND_ROYAL_SUTDA,

    }
  },

  methods:{
    initLeisureGameArttributeConfig() {
      getLeisureGameAttributeConfig().then(res => {
        if (res.data.success) {
          this.attrConfig = res.data.data

          //배당설정
          this.normalConfig = this.attrConfig.find(attr => {
            return (attr.idx === leisureConst.LEISURE_ATTRIBUTE_ROYAL_SUTDA_NORMAL)
          })
        }
      })
    }
  },
  created() {
    this.initKindConfig(this.kind)
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMECOMP,leisureConst.LEISURE_COMP_ROYAL);
    this.$store.commit(RECEIVE_CURRENTLEISUREGAMEID,this.kind);
  },
}
</script>

<style scoped>
@media screen and (max-width: 1024px) {
  .mini_game .dmr {
    min-height: 350px !important;
  }
}
</style>